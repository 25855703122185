<template>
	<the-mandala />
</template>

<script>
	import TheMandala from '../components/TheMandala';
	export default {
		components: { TheMandala },
	};
</script>
