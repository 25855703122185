<template>
	<div class="about">
		<resume-component />
	</div>
</template>

<script>
	import ResumeComponent from '../components/ResumeComponent.vue';
	export default {
		components: { ResumeComponent },
	};
</script>
